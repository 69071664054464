import { Button, ContentBox, Icons } from '@axo/deprecated/util/ui-components';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { Checkbox, TextInput } from '@axo/shared/ui/input';
import { Formatter } from '@axo/shared/util/string';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ChildFormProps } from '../../InsuranceForm';
import styles from '../../InsuranceForm/InsuranceForm.module.scss';
import { useTranslation } from '../../translations/useTranslation';
import { RegNos } from './regNos.ts';

export function AccountNumberForm({
  applyValidation,
  reportValidity,
  emitData,
}: ChildFormProps) {
  const accountFormatter = useMemo(() => new Formatter('****-**********'), []);
  const registrationNumberRegex = /^[0-9]{4}$/;
  const accountNumberRegex = /^[0-9]{7,10}$/;

  const {
    state: { application },
  } = useContext(LoanApplicationContext);
  const existingAccountNumber = accountFormatter.format(
    (application?.AccountNumber ?? '').replaceAll(/\D/g, '')
  );

  const accountNumberFormatter = useMemo(() => new Formatter('**********'), []);
  const registrationNumberFormatter = useMemo(() => new Formatter('****'), []);

  const { t } = useTranslation();

  const [isEditVisible, setIsEditVisible] = useState(false);
  const [triedSave, setTriedSave] = useState(false);
  const [hasConfirmedAccountNumber, setHasConfirmedAccountNumber] =
    useState(true);
  const [registrationNumber, setRegistrationNumber] = useState(
    existingAccountNumber.split('-')[0] ?? ''
  );
  const [accountNumber, setAccountNumber] = useState(
    existingAccountNumber.split('-')[1] ?? ''
  );

  const account = `${registrationNumber}-${accountNumber}`;

  const isFormValid = () =>
    registrationNumberRegex.test(registrationNumber) &&
    RegNos.includes(registrationNumber) &&
    accountNumberRegex.test(accountNumber);

  useEffect(() => {
    reportValidity(hasConfirmedAccountNumber && isFormValid());
    emitData?.('registrationNumber', registrationNumber);
    emitData?.('accountNumber', accountNumber);
  }, [hasConfirmedAccountNumber, registrationNumber, accountNumber]);

  return (
    <ContentBox
      classes={{
        root: styles.contentBox,
      }}
    >
      <div className={styles.header}>
        <h5>{t('Account details')}</h5>
        <Button
          variant="primary-inverted"
          onClick={() => {
            if (isEditVisible) {
              setTriedSave(true);
              if (isFormValid()) {
                setIsEditVisible(false);
              }
            } else {
              setIsEditVisible(true);
            }
          }}
          size="small"
          endIcon={
            isEditVisible ? (
              <Icons.CheckMarkGreen
                color={{ primary: '--primary-500' }}
                size="xs"
              />
            ) : (
              <Icons.Edit color={{ primary: '--primary-500' }} size="xs" />
            )
          }
        >
          {isEditVisible ? t('Save') : t('Edit')}
        </Button>
      </div>

      {isEditVisible && (
        <TextInput
          label={t('Registration number')}
          onChange={(e) => setRegistrationNumber(e.target.value)}
          state={
            (triedSave || applyValidation) &&
            !RegNos.includes(registrationNumber)
              ? 'invalid'
              : 'undetermined'
          }
          value={registrationNumber}
          format={() => registrationNumberFormatter}
        />
      )}

      {isEditVisible && (
        <TextInput
          label={t('Account number')}
          onChange={(e) => setAccountNumber(e.target.value)}
          state={
            (triedSave || applyValidation) &&
            !accountNumberRegex.test(accountNumber)
              ? 'invalid'
              : 'undetermined'
          }
          value={accountNumber}
          format={() => accountNumberFormatter}
        />
      )}
      <Checkbox
        name="hasConfirmedAccountNumber"
        onChange={(e) => setHasConfirmedAccountNumber(e.target.checked)}
        errors={
          !hasConfirmedAccountNumber && applyValidation
            ? [
                {
                  message: t(
                    'Please make a selection in order to proceed with the application.'
                  ),
                },
              ]
            : []
        }
        value={hasConfirmedAccountNumber}
      >
        {t(
          'I agree that payment is made to AIG via payment service from the following account: <XXXX - XXXXXXX>.',
          [account]
        )}
      </Checkbox>
    </ContentBox>
  );
}
