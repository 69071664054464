export const payoutAndPremium = [
  { payout: 0, premium: 0 },
  { payout: 2000, premium: 139 },
  { payout: 3000, premium: 189 },
  { payout: 4000, premium: 249 },
  { payout: 5000, premium: 299 },
  { payout: 6000, premium: 349 },
  { payout: 7000, premium: 409 },
  { payout: 8000, premium: 459 },
  { payout: 9000, premium: 509 },
  { payout: 10000, premium: 559 },
] as const;

export type IPayOutAndPremium = (typeof payoutAndPremium)[number];
