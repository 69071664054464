interface Child {
  age: number;
}

interface Car {
  type: string;
  cost: number;
}

interface Debt {
  [key: string]: number | boolean | string | undefined;
  type: number;
  remainingAmount: number;
  refinance: boolean;
  creditor: string;
  monthlyCost?: number;
  id?: number;
}

interface Quote {
  id: number;
  status: number;
  updatedAt: Date;
  approvedLoanSize: number;
  approvedAmortizeLength: number;
  effectiveInterestRate: number;
  apr: number;
  startupFee: number;
  administrationFee: number;
  monthlyCost: number;
  totalPayment: number;
  link: string;
  coLink: string;
  variableInterestRate: boolean;
  rId: string;
  bankStatus: string;
  bankSubStatus: string;
  lender: Lender;
  score: number;
}

interface Lender {
  id: number;
  name: string;
  label: string;
  logo: string;
  tier: number;
  acceptHeader: string;
  acceptText: string;
  badgeText: string;
  moreInfoText: string;
  filesToUpload: UploadOptions[];
}

interface UploadOptions {
  requestText: string;
  numFiles: number;
  uploadType:
    | 'annual-tax-return'
    | 'salary-latest'
    | 'salary-1'
    | 'salary-2'
    | 'salary-3'
    | 'bs'
    | 'picture-identification'
    | 'r75'
    | 'account-statement'
    | 'annual-accounts'
    | 'sales-lineup';
}

interface User {
  id?: string;
  token?: string;
  username: string;
  age: number;
}

type ValueTypes = string | number | boolean | User | null;

interface ChannelSnapshot {
  utmSource: ValueTypes;
  utmMedium: ValueTypes;
  utmTerm: ValueTypes;
  utmCampaign: ValueTypes;
  utmContent: ValueTypes;
  gclid: ValueTypes;
  keyword: ValueTypes;
  clientId: ValueTypes;
  fbclid: ValueTypes;
  fbp: ValueTypes;
  transactionId: ValueTypes;
  sessionId: ValueTypes;
  documentReferrer: ValueTypes;
  operation: string;
  clickId?: string;
}

export type LendmeApplication = {
  externalReference: number;
  partner: string;
  appliedLoanSize: number;
  amortizeLength: number;
  status: number;
  applicantStatus: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  purpose: number;
  otherPurposeClarification: string;
  maritalStatus: number;
  partnerIncome: number | null;
  hasChildren: boolean;
  children: Child[];
  hasCars: boolean;
  cars: Car[];
  residence: number;
  residenceLength: string;
  rent: number;
  housingBenefits: number;
  childSupport: number;
  otherExtraIncome: number;
  holidayHome: boolean;
  postalNo: string;
  occupation: number;
  employmentLength: string;
  companyCvr: string;
  unemploymentFund: boolean;
  dankortVisadankort: boolean;
  otherPaymentCards: boolean;
  bankLength: string;
  regNo: string;
  accountNo: string;
  grossIncome: number;
  netIncome: number;
  spendingHousehold: number;
  hasDebts: boolean;
  hasHouseDebts: boolean;
  hasCarDebts: boolean;
  hasStudentDebts: boolean;
  hasCollectionDebts: boolean;
  hasPersonalDebts: boolean;
  debts: Debt[];
  paymentRemarks: boolean;
  citizenship: number;
  residencePermit: boolean | null;
  permitPrimaryType: number | null;
  permitSecondaryType: string | null;
  permitSequentialNumber: string | null;
  permitForeignNumber: string | null;
  permitExpire: string | null;
  marketingAccept: boolean;
  creditStatusAccept: boolean;
  termsAccept: boolean;
  userAgent: string;
  startedAtUrl: string;
  lendMeVersion: string;
  createdAt?: Date;
  insurance: boolean;
  insuranceLeads: {
    id: number;
    company: string;
  }[];
  user: User;
  hasCodebtor: boolean;
  coApplication?: LendmeApplication;
  quotes?: Quote[];
  channelSnapshots: ChannelSnapshot[];
  expensePhoneInternetTv: number;
  expenseTransport: number;
  expenseElectricity: number;
  expenseWater: number;
  expenseHeating: number;
  expenseInsurances: number;
  expensesShare: number | null;
  debtThisYear: number;
}

export enum ApplicationStatus {
  APPLICATION_RECEIVED = 2,
  APPLICATION_OFFERED_AN_OFFER = 3,
  APPLICATION_OFFERED_ALL_OFFERS = 4,
}
