import { Hr, Icons } from '@axo/deprecated/util/ui-components';
import {
  InsuranceContext,
  StepContext,
  useCreateInsuranceEvent,
} from '@axo/insurance/feature/providers';
import { NavWrapper, PageSpinner, StepNavigator } from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { useCreateInsuranceBsMandate, useInsurancePolicy } from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { classNames } from '@axo/shared/util/dom';
import { debounceFunction } from '@axo/shared/util/fp';
import { useContext, useMemo, useRef, useState } from 'react';
import { usePayoutAndPremium } from '../(deprecated)StepProvider/steps/Explainer/usePayoutAndPremium';
import formStyles from '../InsuranceForm/InsuranceForm.module.scss';
import { OnEnd } from '../InsuranceProvider';
import AigLogo from '../assets/AigLogoBlack.svg';
import {
  CoverageSlider,
  EligibilityCriteria,
  Error,
  ExplainerText,
  Questionnaire,
  Summary,
  TermsForm,
} from '../components';
import { AccountNumberForm } from '../components/AccountNumberForm/AccountNumberForm';
import collapsibleStyles from '../components/CollapsibleStep/Collapsible.module.scss';
import { IPayOutAndPremium } from '../const';
import { useTranslation } from '../translations/useTranslation';

type InsuranceFormProps = {
  onEnd: OnEnd;
  onError: (error: Error) => void;
};

export function InsuranceForm({
  onEnd = { action: () => undefined },
  onError = () => undefined,
}: InsuranceFormProps) {
  const { state: authState } = useContext(DataAccessContext);
  const { state: applicationState } = useContext(LoanApplicationContext);
  const { state: stepState, dispatch } = useContext(StepContext);
  const { getPayoutAndPremiumItem } = usePayoutAndPremium();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState(
    getPayoutAndPremiumItem(5000)
  );
  const [triedContinue, setTriedContinue] = useState(false);
  const [fieldsValidity, setFieldsValidity] = useState({
    questionnaire: false,
    terms: false,
    account: false,
  });
  const [accountNumberFormData, setAccountNumberFormData] = useState({
    registrationNumber: '',
    accountNumber: ''
  })
  const [isFormCompleted, setFormCompleted] = useState(false);
  const checkboxes = useRef<HTMLDivElement>(null);
  const radios = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const insurancePolicyReference = useContext(InsuranceContext).state.insurancePolicyReference!;
  const fetchInsurancePolicy = useInsurancePolicy(insurancePolicyReference.PolicyID);
  const insurancePolicy = fetchInsurancePolicy.data!;
  const log = useEventLogger();
  const addEvent = useCreateInsuranceEvent();
  const { track } = useAnalytics();
  useEventField('current-step', 'explainer');

  const createInsurancePaymentMandate = useCreateInsuranceBsMandate();
  const userName =
    `${insurancePolicyReference.FirstName} ${insurancePolicyReference.LastName}`.trim();

  async function handleDeclineInsurance() {
    log(EventCode.InsuranceDeclined);
    addEvent('dk/form/insurance-declined', 'form');
    await track({
      event: 'Insurance Declined',
      options: { send_immediately: true },
      params: { step: 'form' },
    });

    const token = authState.user.token;
    const uid = applicationState.application?.ID;
    onEnd.action({token, uid});
  }

  function createPaymentMandate() {
    return createInsurancePaymentMandate.mutateAsync({
      CustomerID: insurancePolicy.CustomerID,
      MarketCountry: insurancePolicy.MarketCountry,
      InsuranceID: insurancePolicy.ID,
      Amount: selectedPayout.payout,
      RegistrationNumber: accountNumberFormData.registrationNumber,
      AccountNumber: accountNumberFormData.accountNumber
    });
  }

  async function handleNext() {
    setTriedContinue(true);
    const isValid = Object.values(fieldsValidity).every((isValid) => isValid);
    if (isValid) {
      log(EventCode.InsuranceNext);
      await track({
        event: `Insurance Completed`,
        options: {
          send_immediately: true,
        },
      });
      try {
        setIsLoading(true);
        await createPaymentMandate();
        // Backend needs some time to update insurance policy
        await sleep(100);
        await fetchInsurancePolicy.refetch();
        setFormCompleted(true);
      } catch (e) {
        // TODO: process response and BE validation for fields
        log(EventCode.PaymentFailed, { 'error-message': e as string });
        dispatch({
          type: 'Set error',
          scope: { parentType: 'error' },
          payload: 'Payment failed',
        });
        console.error(e);
      } finally {
        // useEventField('is-payment-successful', (Boolean(stepState.error) ?? false).toString());
        setIsLoading(false);
      }
    } else {
      const scrollViewRef = !fieldsValidity.questionnaire ? radios : checkboxes;
      scrollViewRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const onAmountSelect = useMemo(
    () => (insurance: IPayOutAndPremium | null) => {
      if (!insurance) {
        return;
      }
      setSelectedPayout(getPayoutAndPremiumItem(insurance.payout));
      debounceFunction((newPayout: number) => {
        log(EventCode.SelectCoverage);
        track({
          event: 'Insurance Coverage Amount Changed',
          params: {
            payout: newPayout,
          },
        });
      })(insurance.payout);
    },
    []
  );

  function setValidity(field: string, validity: boolean) {
    setFieldsValidity({ ...fieldsValidity, [field]: validity });
  }

  function setData(field: string, value: string) {
    setAccountNumberFormData((prevState) => ({ ...prevState, [field]: value }));
  }

  if (isLoading) return <PageSpinner />;

  if (stepState.error) return <Error error={stepState.error} onEnd={onEnd} onNext={() => dispatch({
    type: 'Set error',
    scope: { parentType: 'error' },
    payload: '',
  })} />;

  const onSummaryEnd = () => {
    let uid;
    if (applicationState.application && applicationState.application.LegacyID) {
      uid = applicationState.application.LegacyID.toString();
    } else if (applicationState.application && applicationState.application.ID) {
      uid = applicationState.application?.ID;
    }

    const token = authState.user.legacyToken ? authState.user.legacyToken : authState.user.token;
    onEnd.action({ token, uid });
  }

  if (isFormCompleted) {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    return <Summary onEnd={onSummaryEnd} selectedInsurance={selectedPayout} />;
  }

  return (
    <div className={collapsibleStyles.collapsible}>
      <div className={classNames(collapsibleStyles.header)}>
        <Icons.Shield color={{ primary: '--primary-500' }} size="sm" />
        {t('<User name> would you like to get financial security?', [
          userName,
        ])}
        <span></span>
      </div>
      <div className={collapsibleStyles.body}>
        <div className={formStyles.stepContent}>
          <ExplainerText />
          <CoverageSlider
            onChange={(value: number) =>
              onAmountSelect(getPayoutAndPremiumItem(value))
            }
            value={selectedPayout}
          />
          <Questionnaire
            applyValidation={triedContinue}
            ref={radios}
            reportValidity={(e) => setValidity('questionnaire', e)}
          />
          <EligibilityCriteria />
          <Hr className={formStyles.separator} />
          <TermsForm
            applyValidation={triedContinue}
            ref={checkboxes}
            reportValidity={(e) => setValidity('terms', e)}
          />
          <AccountNumberForm
            applyValidation={triedContinue}
            reportValidity={(e) => setValidity('account', e)}
            emitData={(...args) => setData(...args)}
          />
          <NavWrapper className={classNames(formStyles.stepNavigation, formStyles.sticky)}>
            <StepNavigator
              layout={'vertical'}
              leftButton={{
                width: 'standard',
                text: t('Skip and see offers'),
                variant: 'ghost',
                onClick: () => handleDeclineInsurance(),
              }}
              rightButton={{
                width: 'standard',
                text: t('Continue with insurance'),
                variant: 'enabled',
                onClick: () => handleNext(),
              }}
            />
          </NavWrapper>
          <p className={formStyles.aig}>
            {t('Insurance provided in collaboration with')}
            <img src={AigLogo} alt="AIG Insurance" />
          </p>
        </div>
      </div>
    </div>
  );
}

export type ChildFormProps = {
  applyValidation: boolean;
  reportValidity: (isValid: boolean) => void;
  emitData?: (fieldName: string, fieldValue: string) => void
};
async function sleep(timoutMs: number) {
  return new Promise((resolve) => setTimeout(resolve, timoutMs));
}

