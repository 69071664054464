import { IStepperStep } from '@axo/deprecated/util/ui-components';
import { insurance_payment } from '@axo/shared/data-access/types';
import { ErrorCode, IPayOutAndPremium, payoutAndPremium } from '../../const';
import { useTranslation } from '../../translations/useTranslation';
import { Action } from './actions';

export interface IStepState<T extends keyof State['steps']>
  extends IStepperStep {
  name: T;
}

export interface IExplainer extends IStepState<'explainer'> {
  insurance: IPayOutAndPremium;
  address: IAddress;
  isAddressFormShown: boolean;
}

export type ISummary = IStepState<'summary'>;
export interface IConfirmation extends IStepState<'confirmation'> {
  name: 'confirmation';
  hasConfirmedEligibility: boolean;
  hasConfirmedTerms: boolean;
  hasConfirmedUsePreviosPayment: boolean;
  hasOtherInsurance: boolean;
  hasOtherPayouts: boolean;
}

export type IAddress = {
  city: string;
  street: string;
  zip: string;
};

export type IStepName = keyof State['steps'];

export type IStep = State['steps'][keyof State['steps']];

export type IPaymentData = {
  payment: insurance_payment.Payment | null;
  isCompleted: boolean;
  paymentStateKey: string | null;
  completeStateKey: string | null;
};

export type State = {
  error: ErrorCode | undefined;
  selectedStep: IStepName;
  payment: IPaymentData;
  steps: {
    explainer: IExplainer;
    confirmation: IConfirmation;
    summary: ISummary;
  };
};

// This rule is disabled as useTranslation is not a hook, it just has a name of the hook.
// When i18n is implemented instead of @axo/shared/util/translation, this function should be replaced to
// const { t } = i18n; // i18n is imported from @axo/shared/localization
// And ideally this should not be in reducer at all, but instead in relevant components.
// eslint-disable-next-line react-hooks/rules-of-hooks
const { t } = useTranslation();

export const initialState: State = {
  error: undefined,
  selectedStep: 'explainer',
  payment: {
    payment: null,
    isCompleted: false,
    paymentStateKey: null,
    completeStateKey: null,
  },
  steps: {
    explainer: {
      name: 'explainer',
      state: 'active',
      insurance: findCoverage(5000),
      address: {
        city: '',
        street: '',
        zip: '',
      },
      isAddressFormShown: false,
    },
    confirmation: {
      name: 'confirmation',
      state: 'untouched',
      hasConfirmedEligibility: false,
      hasConfirmedTerms: false,
      hasConfirmedUsePreviosPayment: false,
      hasOtherInsurance: false,
      hasOtherPayouts: false,
    },
    summary: {
      name: 'summary',
      state: 'untouched',
    },
  },
};

function findCoverage(payout: IPayOutAndPremium['payout']): IPayOutAndPremium {
  const coverage = payoutAndPremium.find((x) => x.payout === payout);
  if (!coverage) throw new Error(`No coverage for payout=${payout} found!`);
  return coverage;
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'Set error':
      return { ...state, error: action.payload };
    case 'Set step': {
      return {
        ...state,
        selectedStep: action.payload,
        steps: {
          ...state.steps,
          [action.payload]: {
            ...state.steps[action.payload],
            state: 'active',
          },
        },
      };
    }
    case 'Set step data': {
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.scope.parentType]: {
            ...state.steps[action.scope.parentType],
            ...action.payload,
          },
        },
      };
    }
    case 'Set payment data': {
      return {
        ...state,
        payment: {
          ...state.payment,
          ...action.payload,
        },
      };
    }
    case 'Reset step states': {
      return {
        ...state,
        error: undefined,
        payment: {
          ...state.payment,
          isCompleted: false,
        },
        steps: {
          ...state.steps,
          explainer: {
            ...state.steps.explainer,
            state: 'untouched',
          },
          confirmation: {
            ...state.steps.confirmation,
            state: 'untouched',
          },
          summary: {
            ...state.steps.summary,
            state: 'untouched',
          },
        },
      };
    }
    default:
      return state;
  }
}
