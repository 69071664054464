import { ReactNode, useReducer } from 'react';
import { insurance } from '@axo/shared/data-access/types';
import { PageSpinner } from '@axo/insurance/ui';
import { InsuranceContent } from './InsuranceContent';
import {
  InsuranceContext,
  InsuranceState,
  insuranceInitialState,
  reducer,
} from '@axo/insurance/feature/providers';

export type OnEnd = {
  action: (params?: {token?: string, uid?: string}) => void;
  summaryButtonText?: string;
};

export type OnError = () => void;

export type InsuranceProviderProps = {
  children: ReactNode;
  onEnd: OnEnd;
  spinner?: ReactNode;
  sourceTag: insurance.InsuranceSourceTag;
  initialState?: InsuranceState;
};

export function InsuranceProvider({
  children,
  onEnd,
  spinner = <PageSpinner />,
  sourceTag,
  initialState = insuranceInitialState,
}: InsuranceProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <InsuranceContext.Provider value={{ state, dispatch }}>
      <InsuranceContent {...{ children, onEnd, spinner, sourceTag }} />
    </InsuranceContext.Provider>
  );
}
