const LEGACY_API = import.meta.env.VITE_APP_LEGACY_API;
const LEGACY_THANK_YOU_PAGE = import.meta.env.VITE_APP_THANK_YOU_PAGE;

type getLegacyRedirectArgs = {
  redirectURL: string;
  id: string;
  jwt: string;
};
export const getLegacyRedirect = async ({
  redirectURL,
  id,
  jwt,
}: getLegacyRedirectArgs) => {
  try {
    const response = await fetchWithRetries(
      `${LEGACY_API}user/oneplatform?application-id=${id}`,
      {
        headers: {
          Authorization: jwt,
        },
      }
    );

    const { uid, token } = await response.json();

    if (!uid || !token) throw new Error('uid | token is undefined');

    return new URL(`${redirectURL}/${uid}/${token}`);
  } catch (error) {
    if (error instanceof Error)
      console.error('Failed to get Legacy Tokens', error?.message);

    return new URL(LEGACY_THANK_YOU_PAGE);
  }
};

const fetchWithRetries = async (
  url: string,
  options: RequestInit,
  retryCount = 0,
  maxRetries = 5,
  delay = 5000
): Promise<Response> => {
  try {
    return await fetch(url, options);
  } catch (error) {
    if (retryCount < maxRetries) {
      console.warn(
        `Fetch failed. Retrying attempt ${retryCount + 1}/${maxRetries}...`
      );

      await new Promise((res) => setTimeout(res, delay));
      return fetchWithRetries(url, options, retryCount + 1, maxRetries, delay);
    }
    throw error;
  }
};
