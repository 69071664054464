import { AnalyticsProvider } from '@axo/shared/services/analytics';
import { getMixpanelConfig } from '@axo/shared/services/analytics/mixpanel/config/getMixpanelConfig';
import { getLDConfigWithAnonymousUser } from '@axo/services/launch-darkly/config/getLDConfig';
import * as Sentry from '@sentry/react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import './base.css';
import { onInsuranceEnd, onInsuranceError } from './config';
import './index.scss';
import { Main } from './pages/Main/Main';

const LD_CLIENT_ID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT;
const LD_CONFIG = getLDConfigWithAnonymousUser({
  clientSideID: LD_CLIENT_ID,
  context: {
    site: {
      kind: 'insurance',
      key: 'ins-dk',
      country: 'dk',
    },
  },
});

!import.meta.env.CI &&
  mixpanel.init(
    import.meta.env.VITE_MIXPANEL_TOKEN as string,
    getMixpanelConfig()
  );

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE !== 'development',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
      stickySession: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  const ldClient = useLDClient();

  return (
    <React.StrictMode>
      <AnalyticsProvider
        enabledServices={[
          'mixpanel',
          { service: 'launchDarkly', params: { client: ldClient } },
          'clarity',
        ]}
      >
        <div className="background"></div>
        <Main onEnd={onInsuranceEnd} onError={onInsuranceError} />
      </AnalyticsProvider>
    </React.StrictMode>
  );
}

export default withLDProvider(LD_CONFIG)(App);
