import { A, Hr, Icons, P } from '@axo/deprecated/util/ui-components';
import { NavWrapper, StepNavigator } from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { useAnalytics } from '@axo/shared/services/analytics';
import { classNames } from '@axo/shared/util/dom';
import formStyles from '../../InsuranceForm/InsuranceForm.module.scss';
import AigLogo from '../../assets/AigLogoBlack.svg';
import {
  IPayOutAndPremium,
  currencyFormatter,
  prePurchaseInformationUrl,
  productFactSheetUrl,
} from '../../const';
import { useTranslation } from '../../translations/useTranslation';
import styles from './Summary.module.scss';

export const Summary = ({
  onEnd,
  selectedInsurance,
}: {
  onEnd?: () => void;
  selectedInsurance: IPayOutAndPremium;
}) => {
  const { t } = useTranslation();
  const log = useEventLogger();
  const { track } = useAnalytics();
  useEventField('current-step', 'summary');

  async function handleNext() {
    log(EventCode.InsuranceNext);
    await track({
      event: 'Insurance Step summary Completed',
      options: {
        send_immediately: true,
      },
    });

    if (onEnd) {
      onEnd();
    }
  }

  return (
    <div className={classNames(formStyles.stepContent, styles.summary)}>
      <h3 className={styles.thankYou}>
        <Icons.Shield size="md" color={{ primary: '--primary-500' }} />
        {t('Thank you for your purchase')}!
      </h3>

      <p>
        {t(
          'Congratulations with your insurance. Foreseeing the future is difficult. Thus protecting your economy is a smart choice in case your life situation suddenly changes.'
        )}
      </p>
      <p>
        {t(
          'An email with details about the insurance will be sent to you within 24 hours. If you have any further questions, do not hesitate to contact us.'
        )}
      </p>

      <Hr className={formStyles.separator} />

      <div className={styles.infoBox}>
        <div className={styles.infoHeader}>
          <h4 className={styles.thankYou}>
            <Icons.Shield size="sm" color={{ primary: '--primary-500' }} />
            {t('Purchase overview')}
          </h4>
        </div>

        <div className={styles.infoItem}>
          <span className={styles.value}>
            +{currencyFormatter(selectedInsurance.payout)}/{t('month')}
          </span>
        </div>
        <div className={styles.infoItem}>
          <P className={styles.label}>{t('Monthly payment')}</P>
          <span className={styles.value}>
            -{currencyFormatter(selectedInsurance.premium)}/{t('month')}
          </span>
        </div>
        <p className={formStyles.extraInfoLabel}>
          {t('Get a copy of the insurance details below:')}
        </p>
        <div className={formStyles.extraInfo}>
          <A
            href={productFactSheetUrl}
            className={formStyles.extraInfoLink}
            target="_blank"
            rel="noreferrer"
          >
            {t('Product fact-sheet')}
            <Icons.Download size="sm" />
          </A>

          <A
            href={prePurchaseInformationUrl}
            className={formStyles.extraInfoLink}
            target="_blank"
            rel="noreferrer"
          >
            {t('Pre-purchase information')}
            <Icons.Download size="sm" />
          </A>
        </div>
      </div>

      <NavWrapper className={formStyles.stepNavigation}>
        <StepNavigator
          rightButton={{
            text: t('See my offers'),
            variant: 'enabled',
            width: 'full-width',
            onClick: handleNext,
          }}
        />
      </NavWrapper>
      <p className={formStyles.aig}>
        {t('Insurance provided in collaboration with')}
        <img src={AigLogo} alt="AIG Insurance" />
      </p>
    </div>
  );
};
