import { insurance_payment_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { useMutation, useQueryClient } from 'react-query';
import { insuranceCustomerKeys } from '../cache-keys/insuranceCustomerKeys';

export function useCreateInsuranceBsMandate() {
  const client = useQueryClient();
  const api = useAPI();
  return useMutation(insurance_payment_api.createBsMandate(api), {
    onSuccess: (x) => {
      const cacheKey = insuranceCustomerKeys.root({
        insuranceID: x.InsuranceID,
        customerID: x.CustomerID,
        marketCountry: x.MarketCountry,
      });
      client.invalidateQueries(cacheKey);
    },
  });
}
