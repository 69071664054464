import { ApplicationStatus } from '@axo/insurance/feature/dk';
import { getLegacyRedirect } from './getLegacyRedirect';

type RedirectArgs = {
  uid?: string;
  token?: string;
};

export const redirect = async (args?: RedirectArgs) => {
  let redirectUrl = new URL(import.meta.env?.VITE_APP_THANK_YOU_PAGE);

  if (!args) {
    // Redirect user
    window.location.href = redirectUrl.href;
    return;
  }

  const { token, uid } = args;

  const params = new URLSearchParams(window.location.search);

  if (params.has('redirectUrl')) {
    redirectUrl = new URL(
      params.get('redirectUrl') ?? import.meta.env?.VITE_APP_THANK_YOU_PAGE
    );
    if (uid) redirectUrl.searchParams.set('id', uid);
    if (token) redirectUrl.hash = `token=${token}`;

    // Redirect user
    window.location.href = redirectUrl.href;
    return;
  }

  if (token && uid) {
    // Application from 1P Form DK
    // Temporary workaround while My Page DK is on legacy
    if (params.get('source') === '1P') {
      redirectUrl = await getLegacyRedirect({
        redirectURL: import.meta.env.VITE_APP_REDIRECT_URL,
        id: uid,
        jwt: token,
      });
      // Redirect user to legacy DK
      window.location.href = redirectUrl.href;
      return;
    }

    const status = await getApplicationStatus(args);

    switch (status) {
      case ApplicationStatus.APPLICATION_OFFERED_ALL_OFFERS:
        redirectUrl = new URL(
          `${import.meta.env?.VITE_APP_MY_PAGE}/${uid}/${token}`
        );
        break;

      default:
        redirectUrl = new URL(
          `${import.meta.env?.VITE_APP_THANK_YOU_PAGE}/${uid}/${token}`
        );
        break;
    }
  }

  // Redirect user
  window.location.href = redirectUrl.href;
};

const getApplicationStatus = async (args: RedirectArgs) => {
  const api = import.meta.env?.VITE_APP_LEGACY_API;

  const { uid, token } = args;

  try {
    const response = await fetch(
      `${api}user/me?_format=json&uid=${uid}&token=${token}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const data = await response.json();

    return data.status satisfies ApplicationStatus;
  } catch (error) {
    if (error instanceof Error)
      console.error(`Error fetching application status: ${error?.message}`);

    return 0;
  }
};
