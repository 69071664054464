import { NavWrapper, StepNavigator } from '@axo/insurance/ui';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useContext } from 'react';
import { OnEnd } from '../../InsuranceProvider';
import { useTranslation } from '../../translations/useTranslation';
import styles from './Error.module.scss';

export const Error = ({
  error,
  onEnd,
  onNext,
}: {
  error: string;
  onEnd: OnEnd;
  onNext: () => void;
}) => {
  const { t } = useTranslation();
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);

  function handleNext() {
    onNext();
  }

  function handleDeclineInsurance() {
    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  const getHeadline = () => {
    switch (error) {
      case 'Payment failed':
        return t('Payment failed - headline');
      case 'Session expired':
        return t('Session expired - headline');
      case 'Something went wrong':
        return t('Something went wrong - headline');
    }
  };

  const getBodyText = () => {
    switch (error) {
      case 'Payment failed':
        return t('Payment failed - body');
      case 'Session expired':
        return t('Session expired - body');
      case 'Something went wrong':
        return t('Something went wrong - body');
    }
  };

  const getContinueButtonText = () => {
    switch (error) {
      case 'Payment failed':
        return t('Yes, continue with insurance');
      case 'Something went wrong':
        return t('Refresh page');
    }
  };

  const getBackButtonText = () => {
    switch (error) {
      case 'Payment failed':
        return t('No, move forward without insurance');
      case 'Something went wrong':
        return t('Try later');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.errorContainer}>
        <h3 className={styles.headline}>{getHeadline()}</h3>
        {getBodyText()}
      </div>
      <NavWrapper>
        <StepNavigator
          leftButton={{
            variant: 'ghost',
            text: getBackButtonText(),
            onClick: handleDeclineInsurance,
            width: 'standard',
          }}
          rightButton={{
            variant: 'enabled',
            text: getContinueButtonText(),
            onClick: handleNext,
            width: 'standard',
          }}
        />
      </NavWrapper>
    </div>
  );
};
