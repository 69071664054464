import { Icons } from '@axo/deprecated/util/ui-components';
import { Slider } from '@axo/shared/ui/input';
import { classNames } from '@axo/shared/util/dom';
import {
  IPayOutAndPremium,
  currencyFormatter,
  payoutAndPremium,
} from '../../const';
import { useTranslation } from '../../translations/useTranslation';
import styles from './CoverageSlider.module.scss';

export const CoverageSlider = ({
  value,
  onChange,
}: {
  value: IPayOutAndPremium;
  onChange: (value: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.coverageSliderContainer}>
      <h3 className={styles.insuranceLabel}>
        <Icons.Shield color={{ primary: '--primary-700' }} />
        {t('Select your desired coverage')}
      </h3>
      <div className={styles.coverageSlider}>
        <div className={classNames(styles.coverageSliderItem, styles.payout)}>
          <p className={styles.value}>{currencyFormatter(value.payout)}</p>
        </div>
        <div
          className={classNames(styles.coverageSliderItem, styles.rightAlign)}
        >
          <p className={styles.payoutLabel}>
            {t('Monthly payment')}
            <strong>&nbsp;{currencyFormatter(value.premium)}</strong>
          </p>
        </div>
      </div>
      <Slider
        min={payoutAndPremium[1].payout}
        max={payoutAndPremium[payoutAndPremium.length - 1].payout}
        step={1000}
        value={value.payout}
        onChange={onChange}
        valueSuffix={'kr'}
      />
    </div>
  );
};
