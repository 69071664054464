import { Icons } from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';
import styles from '../InsuranceForm/InsuranceForm.module.scss';
import AigLogo from '../assets/AigLogoBlack.svg';
import { useTranslation } from '../translations/useTranslation';

export function ExplainerText() {
  const { t } = useTranslation();
  return (
    <>
      <p>
        {t(
          'Since anyone can have an accident, Lendme strongly recommends that you protect your finances with Lendme Økonomiforsikring.'
        )}
      </p>

      <ul>
        {[
          t('Maintain financial peace of mind even in emergency situations'),
          t('Keep your finances stable when unemployment hits or you get sick'),
          t(
            'Payment protection is valid around the clock and around the world for the first 12 months'
          ),
        ].map((content, i) => (
          <li key={`explainer-item-${i}`}>
            <Icons.badgeCheck size="sm" color={{ primary: '--primary-500' }} />
            <span>{content}</span>
          </li>
        ))}
        <li className={classNames(styles.mobileOnly, styles.bold)}>
          <img src={AigLogo} alt="AIG Insurance" />
          {t(
            "The insurance is taken out with AIG Insurance, which is one of the world's leading insurance companies."
          )}
        </li>
      </ul>
      <p className={styles.bold}>{t('Read more about insurance here.')}</p>
    </>
  );
}
