import { InsuranceContext } from '@axo/insurance/feature/providers';
import { useInsurancePolicy } from '@axo/shared/data-access/hooks';
import { useCallback, useContext, useState } from 'react';
import { payoutAndPremium } from '../../../const/payoutAndPremium';
import { StepContext } from '../../store/context';

export const usePayoutAndPremium = () => {
  const { dispatch, state } = useContext(StepContext);
  const {
    steps: { explainer: stepData },
  } = state;
  const { state: insuranceState } = useContext(InsuranceContext);
  const insurancePolicy = useInsurancePolicy(
    insuranceState.insurancePolicyReference?.PolicyID
  );

  const [payoutChanged, setPayoutChanged] = useState(false);

  const getPayoutAndPremiumItem = useCallback(
    (amount: number) =>
      payoutAndPremium.find((insuranceSet) => amount === insuranceSet.payout) ||
      stepData.insurance,
    [stepData.insurance]
  );

  return {
    getPayoutAndPremiumItem,
    setPayoutChanged,
  };
};
