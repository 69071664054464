import { Languages, getTranslation } from '@axo/shared/util/translation';
import { SHOW_DEBUG_ERRORS } from '../const';
import { translations } from './translations';

export function useTranslation() {
  return getTranslation<typeof translations, Languages.dk>({
    translations,
    preferredLanguage: Languages.dk,
    showDebugErrors: SHOW_DEBUG_ERRORS,
  });
}
