import { createContext } from 'react';
import { OnEnd } from '../../InsuranceProvider';
import { Action } from './actions';
import { initialState, State } from './reducer';

export const StepContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
  onEnd: OnEnd;
  onError: (error: Error) => void;
}>({
  state: initialState,
  dispatch: () => undefined,
  onEnd: { action: () => undefined },
  onError: () => undefined,
});
