import { classNames } from '@axo/shared/util/dom';
import AigLogo from '../../assets/AigLogoWhite.svg';
import { useTranslation } from '../../translations/useTranslation';
import styles from './PoweredByAig.module.scss';

export function PoweredByAig({ layout }: { layout: 'mobile' | 'desktop' }) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        styles.poweredByAig,
        layout === 'mobile' ? styles.mobile : styles.desktop
      )}
    >
      <h3>{t('Lendme income protection insurance')}</h3>
      <p>
        {t(
          "The insurance is taken out with AIG Insurance, which is one of the world's leading insurance companies."
        )}
      </p>
      <p className={styles.poweredBy}>
        {t('Powered by')} <img src={AigLogo} alt="AIG Insurance" />
      </p>
    </div>
  );
}
