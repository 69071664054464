import { ContentBox } from '@axo/deprecated/util/ui-components';
import { Checkbox } from '@axo/shared/ui/input';
import { ForwardedRef, forwardRef, useState } from 'react';
import styles from '../(deprecated)StepProvider/steps/Confirmation/Confirmation.module.scss';
import { ChildFormProps } from '../InsuranceForm';
import { prePurchaseInformationUrl, productFactSheetUrl, termsAndConditionsUrl } from '../const';
import { useTranslation } from '../translations/useTranslation';

function TermsForm(
  { applyValidation, reportValidity }: ChildFormProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [hasConfirmedEligibility, setHasConfirmedEligibility] = useState(false);
  const [hasConfirmedTerms, setHasConfirmedTerms] = useState(false);
  const { t } = useTranslation();
  return (
    <ContentBox
      classes={{
        root: styles.contentBox,
      }}
      ref={ref}
    >
      <Checkbox
        name="hasConfirmedEligibility"
        onChange={(e) => {
          setHasConfirmedEligibility(e.target.checked);
          reportValidity(e.target.checked && hasConfirmedTerms);
        }}
        errors={
          !hasConfirmedEligibility && applyValidation
            ? [
                {
                  message: t(
                    'Please make a selection in order to proceed with the application.'
                  ),
                },
              ]
            : []
        }
        value={hasConfirmedEligibility}
      >
        {t(
          'I confirm that I meet the above conditions for purchasing the insurance. I also confirm that I have read the document with advance information, the insurance conditions and the document with information about the insurance.'
          , [prePurchaseInformationUrl, termsAndConditionsUrl, productFactSheetUrl])}
      </Checkbox>
      <Checkbox
        name="hasConfirmedTerms"
        onChange={(e) => {
          setHasConfirmedTerms(e.target.checked);
          reportValidity(e.target.checked && hasConfirmedEligibility);
        }}
        errors={
          !hasConfirmedTerms && applyValidation
            ? [
                {
                  message: t(
                    'Please make a selection in order to proceed with the application.'
                  ),
                },
              ]
            : []
        }
        value={hasConfirmedTerms}
      >
        {t(
          'I understand that the insurance is valid regardless of whether I am granted a loan or not.'
        )}
      </Checkbox>
    </ContentBox>
  );
}

export default forwardRef(TermsForm);
